<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                辅助工具出入库详情
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">编号：</div>
                    <div class="info">{{ auxilaryCrkInfo.id }}</div>
                </div>
                <div class="item">
                    <div class="label">单号：</div>
                    <div class="info">{{ auxilaryCrkInfo.oddNum }}</div>
                </div>
                <div class="item">
                    <div class="label">条码/标签：</div>
                    <div class="info">{{ auxilaryCrkInfo.auxCode }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">工具分类：</div>
                    <div class="info">{{ auxilaryCrkInfo.className }}</div>
                </div>
                <div class="item">
                    <div class="label">工具名称：</div>
                    <div class="info">{{ auxilaryCrkInfo.auxName }}</div>
                </div>
                <div class="item">
                    <div class="label">工具规格型号:</div>
                    <div class="info">{{ auxilaryCrkInfo.auxType }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">工具编号：</div>
                    <div class="info">{{ auxilaryCrkInfo.auxNum }}</div>
                </div>
                <div class="item">
                    <div class="label">工作地点：</div>
                    <div class="info">{{ auxilaryCrkInfo.workplace }}</div>
                </div>
                <div class="item">
                    <div class="label">工作内容：</div>
                    <div class="info">{{ auxilaryCrkInfo.workContent }}</div>
                </div>
            </div>

            <div class="df">
                <div class="item">
                    <div class="label">出库经办人：</div>
                    <div class="info">{{ auxilaryCrkInfo.ckJbr }}</div>
                </div>
                <div class="item">
                    <div class="label">出库日期：</div>
                    <div class="info">{{ auxilaryCrkInfo.ckDate }}</div>
                </div>
                <div class="item">
                    <div class="label">出库时间：</div>
                    <div class="info">{{ auxilaryCrkInfo.ckTime }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">预计入库日期：</div>
                    <div class="info">{{ auxilaryCrkInfo.predictRkDate }}</div>
                </div>
                <div class="item">
                    <div class="label">入库经办人员：</div>
                    <div class="info">{{ auxilaryCrkInfo.rkJbr }}</div>
                </div>
                <div class="item">
                    <div class="label">实际入库日期：</div>
                    <div class="info">{{ auxilaryCrkInfo.rkDate }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">实际入库时间：</div>
                    <div class="info">{{ auxilaryCrkInfo.rkTime }}</div>
                </div>
                <div class="item">
                    <div class="label">使用评价：</div>
                    <div class="info">{{ auxilaryCrkInfo.useEvaluate }}</div>
                </div>
                <div class="item">
                    <div class="label">备注：</div>
                    <div class="info">{{ auxilaryCrkInfo.remark }}</div>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>

import { queryCrkInfo } from '@/api/auxiliaryCrk.js'
export default {
    props: {
        id: {
            type: [String, Number]
        },
        type: {
            type: [String, Number]
        },
    },
    data() {
        return {
            auxilaryCrkInfo: {
                oddNum: '',
                auxCode: '',
                classCode: '',
                className: '',
                auxName: '',
                auxType: '',
                auxNum: '',
                workplace: null,
                workContent: '',
                ckJbr: null,
                ckDate: '',
                ckTime: '',
                predictRkDate: '',
                rkJbr: null,
                rkDate: '',
                rkTime: '',
                remark: ''
            },

        }
    },
    mounted() {
        this.id && this.type && this.loadAuxiliaryCrkInfo()
    },
    methods: {
        //根据id查询设备信息
        async loadAuxiliaryCrkInfo() {
            await queryCrkInfo(this.id,this.type).then(res => {
                if (res.code === '000000') {
                    this.auxilaryCrkInfo = res.t
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                // width: 100%;
                float: left;
            }
        }
    }

    .df-img {
        width: 100%;
        margin-bottom: 16px;

        .info {
            width: 100%;
            // float: left;
            display: flex;

            .block {
                width: 25%;
                margin-right: 10px;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }


}
</style>